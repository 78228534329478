<template>
  <div>
    <div class="banner_info" :style="`background: url(images/${tabIndex}_02.jpg) center no-repeat;background-size: cover;`">
      <dl>
        <dt>{{name}}</dt>
        <dd>Dynamic generalization</dd>
      </dl>
    </div>
    <div class="infopage">
      <div class="container">
        <div class="searchbox">
          <div class="title"><b>证件查询</b></div>
          <div class="flex">
            <div class="flex_item">
              <div class="flex">
                <span>证件号：</span>
                <div class="flex_item"><input type="text" placeholder="必填"></div>
              </div>
            </div>
            <div class="flex_item">
              <div class="flex">
                <span>企业名称：</span>
                <div class="flex_item"><input type="text" placeholder="必填"></div>
              </div>
            </div>
            <div class="flex_item">
              <div class="flex">
                <span>证书类别：</span>
                <div class="flex_item"><input type="text" placeholder="必填"></div>
              </div>
            </div>
            <div class="flex_item">
              <div class="flex">
                <span>验证码：</span>
                <div class="flex_item"><input type="text" placeholder="必填"></div>
                <img src="images/1_05.jpg" class="code" />
              </div>
            </div>
          </div>
          <button type="button" class="btn">查询</button>
        </div>
      </div>
      <div class="container clearfix" id="container">
        <div class="left">
          <div class="box" v-for="(item,i) in tabData" :key="item.id">
            <dl v-if="i==0">
              <dt>{{name}}</dt>
              <dd>Dynamic generalization</dd>
            </dl>
            <template v-if="level==2">
              <h3 :class="{on:item.id == id,active:cId == item.id}" @click="tabClick(item)">
                <a href="javascript:;">{{item.name}}</a>
                <span v-if="!tabArray.includes(item.name)"><i></i></span>
              </h3>
              <ul :class="{hidden:item.id != id }" v-if="!tabArray.includes(item.name)">
                <li @click="itemClick(val)" :class="{on:cId == val.id && !cTab}" v-for="val in item.childList" :key="val.id"><a href="javascript:;">{{val.name}}</a></li>
              </ul>
            </template>
            <template v-else>
              <h3 :class="{active:index==i}" @click="tabClick(item,i)">
                <a href="javascript:;">{{item.name}}</a>
              </h3>
            </template>
          </div>

        </div>
        <div class="right" v-if="!cTab">
          <div class="title flex lg">
            <div class="flex_item"><b class="">{{tabName}}</b></div>
            <div class="bread">
              <a href="#">首页</a> > <a href="#">{{name}}</a> > <span>{{tabName}}</span>
            </div>
          </div>
          <div class="newsitem" v-html="articleDetail">
          </div>
        </div>
        <div class="right" v-else>
          <div class="box" v-for="item in columnData" :key="item.id">
            <div class="title flex">
              <div class="flex_item"><b>{{item.name}}</b></div>
              <a href="#" class="more">更多</a>
            </div>
            <div class="bd">
              <ul class="newslist">
                <li v-for="val in item.articleList.slice(0,10)" :key="val.id">
                  <div class="flex_item"><a :href="`./detail?pid=${pid}&id=${cId}&tabIndex=${tabIndex}&index=${index}&name=动态概括`" target="_blank">{{val.title}}</a></div>
                  <span>{{val.publishTime}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions,mapState} from 'vuex';
export default {
  name: "detail",
  data(){
    return {
      id:0,
      pid:0,
      tabIndex:0,
      name:'',
      columnData:[],
      cId:0,
      cTab:'',
      articleDetail:'',
      tabArray:['中心动态','行业动态'],
      tabData:[],
      index:0,
      current:{},
      articleId:0,
      level:0,//左侧导航级别
    }
  },
  mounted() {

    this.id  = this.cId = this.$route.query?.id
    this.pid = this.$route.query?.pid
    this.tabIndex = this.$route.query?.tabIndex
    this.name = this.$route.query?.name;
    this.index = this.$route.query?.index;
    this.articleId = this.$route.query?.articleId;
    this.level = this.$route.query?.level;
    this.$store.commit('setTabIndex',this.tabIndex);
    //获取左侧导航数据
    this.findTabData()
    //获取文章详情
    this.getArticleData(this.articleId)
  },
  methods:{
    ...mapActions(['columnAction']),
    async findTabData(){
      let res = await this.$api.getChildColumn({columnId:this.pid});
      this.tabData = res.data.data;
      console.log(this.tabData,'--');
      this.cTab = this.tabData[this.index];
      console.log(this.cTab,'===')
      this.$nextTick(function (){
        this.initTab()
      })
    },
    async findColumnData(){
      let cid = 0
      if(this.level == 2){
         cid = this.cId
      }else{
         cid = this.tabData[this.index].id
      }
      let res = await this.$api.getChildColumn({columnId:cid});
      this.columnData = res.data.data;
      console.log(this.columnData,'--');
    },
    initTab(){
      $("#container .left .box h3").click(function() {
        $(this).toggleClass('on');
        $(this).next("ul").slideToggle();

      });
    },
    tabClick(item,i){
      if(this.level == 2){
        if(this.tabArray.includes(item.name)){
          this.cTab = item
          this.cId = item.id
          this.findColumnData();
        }
      }else{
        this.index = i
        this.findColumnData();
      }

      console.log(this.cTab)
    },
    itemClick(item){
      this.current = item
      this.cTab = ''
      this.cId = item.id
      //获取文章数据
      let articleId =  item.articleList[0]?.id
      this.getArticleData(articleId)
    },
    async getArticleData(articleId){
      let res = await this.$api.getArticleData({id:articleId});
      this.articleDetail = res.data.data?.content;
    },
  },
  computed:{
    tabName(){
      if(this.cTab){
        return this.cTab.name
      }else{
        return this.current.name
      }
    }
  }
}
</script>

<style scoped>

</style>
